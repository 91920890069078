import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { mavenProRegular, mavenProBold } from "./components/Fonts/Fonts";

export const theme = createMuiTheme({
    palette: {
      primary: {
        main: process.env.REACT_APP_COLOR_PRIMARY,
      },
      secondary: {
        main: process.env.REACT_APP_COLOR_SHADE,
      },
      dark: {
        main: process.env.REACT_APP_COLOR_DARK,
      },
      light: {
        main: process.env.REACT_APP_COLOR_LIGHT,
      },
      shade: {
        main: process.env.REACT_APP_COLOR_SHADE,
      },
      background: {
        default: process.env.REACT_APP_COLOR_PRIMARY,
      },
    },
    typography: {
      fontFamily: "MavenProRegular",
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [mavenProRegular, mavenProBold],
        },
      },
      
    },
  });
  
  
  
export const useStyles = makeStyles((theme) => ({
    app: {
        padding: 0,
    },
    root: {
        backgroundColor: process.env.REACT_APP_COLOR_LIGHT,
        marginTop: 10,
        marginBottom: 10,
        paddingTop: 10,
        paddingBottom: 10,
        flexGrow: 1,
        borderWidth: 5,
        borderStyle: "solid",
        borderRadius: 20,
        borderColor: process.env.REACT_APP_COLOR_PRIMARY,
    },
    appHeader: {
        backgroundColor: process.env.REACT_APP_COLOR_LIGHT,
    },
    headerText: {
        color: process.env.REACT_APP_COLOR_PRIMARY,
    },
    formText: {
        color: process.env.REACT_APP_COLOR_LIGHT,
        margin: 0,
        marginTop: 10,
        backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
    },
    select: {
        marginTop: 10,
    },
    formLabel: {
        color: "primary",
    },
    formRow: {
        marginRight: 10,
        marginLeft: 10,
    },
    buttonRow: {
        marginTop: 10,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        
    },
    windowsModal: {
        backgroundColor: process.env.REACT_APP_COLOR_LIGHT,
        marginTop: 10,
        marginBottom: 10,
        paddingTop: 10,
        paddingBottom: 10,
        flexGrow: 1,
        borderRadius: 2,
        boxShadow: theme.shadows[300],
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },


}));
  