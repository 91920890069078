import React from "react";
import {withStyles} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

export function Alert(props) {
    return (<MuiAlert elevation={6} variant="filled" {...props} />);
}

export const SuccessAlert = withStyles({
    standardSuccess:{
      color:process.env.REACT_APP_COLOR_PRIMARY,
      background:process.env.REACT_APP_COLOR_LIGHT,
    },
  
  })(Alert);