import React from "react";
import {Grid, Select,MenuItem, FormControl, TextField, Button, Modal} from "@material-ui/core";
import {useStyles} from "../../themeApp";
import { Formik } from "formik";
import {validationDocumentSchema} from '../../validations/ValidationForms'
import {verificationPerson} from "../../request/requests"
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import SearchIcon from "@material-ui/icons/Search";


export function SendEmailUser(props){

    const {handlerSendingData, handlerShowErrorSnackbar, handlerErrorMessage, handlerShowInfoSnackbar, handlerInfoMessage} = props;

    const classes = useStyles();
    const {idTypes} = props;
    const {handleDataEdit} = props;
    const {disableModalEmailUser} = props;
    const {handleShowModalUserKey} = props;
    const {handleShowModalKey} = props;

    /*------------------------------
    FUNCIONES VENTANA MODAL ENVIO KEY
    -------------------------------*/

    /*Envia el documento para consultar la persona a la cual se le envia la key*/
    async function sendVerificationData(values, resetForm) {

        handlerSendingData(true);
        handleDataEdit(values.peopleData);
    
        let action = await verificationPerson(values.peopleData);
    
        if (!action.error) {
          disableModalEmailUser();
          handleShowModalKey();
          handlerInfoMessage(action.message);
          handlerShowInfoSnackbar(true);
        } else {
          handlerErrorMessage(action.message);
          handlerShowErrorSnackbar(true);
        }
        handlerSendingData(false);   
    }

    return (
        /*---------------------------
        VISTA MODAL ENVIO DE LA KEY
        -----------------------------*/
        <Modal open={props.editUser} onClose={() => disableModalEmailUser()} className={classes.modal}>

            <Grid container justify="center">

                <Formik
                    initialValues={{
                    peopleData: {
                        document_type_id: idTypes && idTypes.length > 0 ? idTypes[0].id : 1,
                        document: "",
                    }
                    }}
                    validationSchema={validationDocumentSchema}
                    onSubmit={(values, { resetForm }) => sendVerificationData(values, resetForm)}
                >
                    {({handleChange, handleBlur, handleSubmit, values, errors, touched, }) => (

                    <Grid item id="form-container" md={4} xs={12}>

                        <Grid container direction="column" justify="center" spacing={2} className={classes.windowsModal} >
                        
                            <Grid item style={{ backgroundColor: process.env.REACT_APP_COLOR_PRIMARY, padding: 0, marginTop: 10, paddingBottom: 5, }} xs={12} md={12} >
                                <Grid container justify="center">
                                <h4 className={classes.formText}>
                                    Verificación de usuario
                                </h4>
                                </Grid>
                            </Grid>


                            <Grid container direction="column" style={{ marginTop: 20 }} className={classes.formRow} justify="center" alignItems="center" >

                                <Grid className={classes.select} item xs={12} md={12} >
                                    <Select value={values.peopleData.document_type_id} onChange={handleChange("peopleData.document_type_id")} autoWidth >
                                    {idTypes ? idTypes.map((item, index) => (
                                            <MenuItem key={item.id} value={item.id}> {`${item.description} (${item.abbreviation})`} </MenuItem>
                                    )) : null}
                                    </Select>
                                </Grid>
                            
                            </Grid>


                            <Grid container direction="column" style={{ marginTop: 20 }} className={classes.formRow} justify="center" alignItems="center" >
                                <Grid className={classes.item} xs={12} item md={12}>
                                    <FormControl>
                                    <TextField
                                        name="peopleData.document"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                        errors.peopleData &&
                                        errors.peopleData.document &&
                                        touched.peopleData &&
                                        touched.peopleData.document
                                        }
                                        helperText={
                                        errors.peopleData &&
                                        errors.peopleData.document &&
                                        touched.peopleData &&
                                        touched.peopleData.document
                                            ? errors.peopleData.document
                                            : ""
                                        }
                                        label="Documento"
                                        id="input-document"
                                        InputLabelProps={{
                                        required: true,
                                        color: "primary",
                                        error: false,
                                        type:"text"
                                        }}
                                        value={values.peopleData.document}
                                    />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container direction="column" style={{ marginTop: 20 }} className={classes.formRow} justify="center" alignItems="center" >
                                <Button
                                    type="submit"
                                    id="btn-search"
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    onClick={() => { disableModalEmailUser(); handleShowModalUserKey()}}
                                    startIcon={<SpellcheckIcon />}
                                    style = {{backgroundColor: "transparent", boxShadow: "none", color:process.env.REACT_APP_COLOR_PRIMARY}}
                                    className={classes.headerText}
                                >
                                si ya tienes una llave de  edición has click AQUÍ
                                </Button>
                            </Grid>      


                            <Grid container direction="row" style={{ marginTop: 30 }} justify="center" >
                                <Button
                                type="submit"
                                id="btn-search"
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={handleSubmit}
                                startIcon={<SearchIcon />}
                                >
                                Consultar
                                </Button>
                            </Grid>

                        </Grid>
                    </Grid>
                    )}
                </Formik>
            </Grid>
        </Modal>
)
}