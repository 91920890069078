import { LOCALE } from '../locales';
import getColombiaDV from './colombiaDV';
import getPanamaDV from './panamaDV';

/**
 * Obtiene el dígito de verificación
 * @param {string} document 
 * @returns 
 */
export function getDV(document) {
    if(!document) {
        return '';
    }

    switch (process.env.REACT_APP_LOCALE) {
        case LOCALE.Colombia:
            return getColombiaDV(document);
        case LOCALE.Panama:
            return getPanamaDV(document)
        default:
            return '';
    }
}