import { object, string, number, mixed } from "yup";
import { LOCALE } from '../locales';

/*----------------------------------------
ESQUEMAS DE VALIDACION FORMULARIO PRESONAS
-----------------------------------------*/

/**
 * Obtener esquema de validación obtenido para la localización configurada
 * @returns {*}
 */
export function localizedValidationSchema(){
  switch (process.env.REACT_APP_LOCALE) {
    case LOCALE.Colombia:
      return coValidationSchema;
    case LOCALE.CostaRica:
      return crValidationSchema; 
    case LOCALE.Panama:
      return paValidationSchema;
    default:
      return coValidationSchema;
  }
}

/**
 * Esquema de validación adaptado para Colombia
 */
export const coValidationSchema = object().shape({
    peopleData: object().shape({
    document: number()
      .integer("El documento debe ser un número")
      .test(
        'NumberDocument',
        "No es posible registrar el consumidor final",
        (value) => String(value).match(/^222222/) ? false : true,
      )
      .test(
        'DocumentSize', 
        'El documento debe tener mínimo 4 digitos', 
        //validar longitud de dcto
        val => !val || (val.toString().length >= 4)
      )
      .typeError("El documento debe ser un número")
      .required("El documento es requerido"),
    name: string()
        .typeError("El primer nombre debe ser una cadena de carácteres"),
        //.required("El nombre es requerido"),
    optional_name: string()
      .typeError("El segundo nombre debe ser una cadena de caracteres"),
    last_name: string()
        .typeError("Los apellidos debe ser una cadena de carácteres"),
        //.required("Los apellidos son requeridos"),
    optional_last_name: string(),
        //.typeError("El segundo apellido de ser una cadena de caracteres"),
    business_name: string()
        .typeError("La razon social debe ser una cadena de caracteres"),
        //.required("La razón social es requerida"),
    rut_file:mixed()
      .test(
        "fileSize",
        "File is too large",
        value => !value || (value && value.size <= 200000)
      )
      .test(
        "fileFormat",
        "Unsupported Format",
        value => !value || (value => value && "application/pdf".includes(value.type))
      ),
    }),
    contactData: object().shape({
    /*address: string().typeError(
        "La dirección debe ser una cadena de caracteres")
        .required("La dirección es requerida"),*/
    postal_code:string()
    .typeError("El código postal debe ser un número")
    .test(
      'PostalCodeSizetSize', 
      'El código postal debe tener 6 digitos', 
      //validar longitud de codigo postal
      val => !val || (val.toString().length == 6)
    )
    .typeError("El código postal debe ser un número"),
    //.required("El código postal es requerido"),
    email_delivery: string()
        .typeError("E-mail inválido")
        .email("E-mail inválido")
        .required("El E-mail es requerido"),
    phone: number()
        .integer("El teléfono debe ser un número")
        .test(
          'PhoneSize', 
          'El teléfono debe tener mínimo 7 digitos', 
          //validar longitud de telefono
          val => !val || (val.toString().length >= 7)
        )
        .typeError("El teléfono debe ser un número")
        //.required("El teléfono es requerido"),
    }),
});

/**
 * Esquema de validación adaptado para Costa Rica
 */
export const crValidationSchema = object().shape({
  peopleData: object().shape({
  document_type_id: number().integer('El tipo de documento debe ser un número').required('El tipo de documento es requerido'),
  document: number()
    .integer("El documento debe ser un número")
    .test(
      'DocumentSize', 
      'El documento debe tener mínimo 4 digitos', 
      //validar longitud de dcto
      val => !val || (val.toString().length >= 4)
    )
    .typeError("El documento debe ser un número")
    .required("El documento es requerido")
    .when('document_type_id', {
      is:(value) => value == 1,
      then: (schema) => schema.test('DocumentSize', 'La Cédula Física debe tener 9 dígitos', val => !val || val.toString().length == 9)
    })
    .when('document_type_id', {
      is:(value) => value == 2,
      then: (schema) => schema.test('DocumentSize', 'La Cédula Jurídica debe tener 10 dígitos', val => !val || val.toString().length == 10)
    })
    .when('document_type_id', {
      is:(value) => value == 3,
      then: (schema) => schema.test('DocumentSize', 'El DIMEX debe tener 12 dígitos', val => !val || val.toString().length == 12)
    })
    ,
  name: string()
      .typeError("El primer nombre debe ser una cadena de carácteres"),
      //.required("El nombre es requerido"),
  optional_name: string()
    .typeError("El segundo nombre debe ser una cadena de caracteres"),
  last_name: string()
      .typeError("Los apellidos debe ser una cadena de carácteres"),
      //.required("Los apellidos son requeridos"),
  optional_last_name: string(),
      //.typeError("El segundo apellido de ser una cadena de caracteres"),
  business_name: string()
      .typeError("La razon social debe ser una cadena de caracteres"),
      //.required("La razón social es requerida"),
  rut_file:mixed()
    .test(
      "fileSize",
      "File is too large",
      value => !value || (value && value.size <= 200000)
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      value => !value || (value => value && "application/pdf".includes(value.type))
    ),
  }),
  contactData: object().shape({
  /*address: string().typeError(
      "La dirección debe ser una cadena de caracteres")
      .required("La dirección es requerida"),*/
  postal_code:string()
  .typeError("El código postal debe ser un número")
  .test(
    'PostalCodeSizetSize', 
    'El código postal debe tener 6 digitos', 
    //validar longitud de codigo postal
    val => !val || (val.toString().length == 6)
  )
  .typeError("El código postal debe ser un número"),
  //.required("El código postal es requerido"),
  email_delivery: string()
      .typeError("E-mail inválido")
      .email("E-mail inválido")
      .required("El E-mail es requerido"),
  phone: number()
      .integer("El teléfono debe ser un número")
      .test(
        'PhoneSize', 
        'El teléfono debe tener mínimo 8 digitos', 
        //validar longitud de telefono
        val => !val || (val.toString().length >= 8)
      )
      .typeError("El teléfono debe ser un número")
      //.required("El teléfono es requerido"),
  }),
})

/**
 * Esquema de validación adaptado para Panamá
 */
export const paValidationSchema = object().shape({
  peopleData: object().shape({
  document_type_id: number().integer('El tipo de documento debe ser un número').required('El tipo de documento es requerido'),
  document: string()
    .test(
      'DocumentSize', 
      'El documento debe tener entre 4 y 30 carácteres', 
      val => !val || (val.toString().length >= 4 && val.toString().length <= 30)
    )
    .required("El documento es requerido")
    .when('document_type_id', {
      is:(value) => value == 1 || value == 3,
      then: (schema) => schema.test('DocumentSize', 'El RUC no cumple con una estructura válida', val => !val || /^(?:(\d+|[A-Za-z]+)-)?(?:NT-)?(\d+)-(\d+)(?:-(\d+))?$/.test(val))
    })
    ,
  name: string()
    .typeError("El primer nombre debe ser una cadena de carácteres")
    /*.when('document_type_id', {
      is: (value) => value == 2 || value == 4,
      then: (schema) => schema.required('El primer Nombre es requerido')
    })*/,
  optional_name: string()
    .typeError("El segundo nombre debe ser una cadena de caracteres"),
  last_name: string()
      .typeError("Los apellidos debe ser una cadena de carácteres")
      /*.when('document_type_id', {
        is: (value) => value == 2 || value == 4,
        then: (schema) => schema.required('El primer Apellido es requerido')
      })*/,
  optional_last_name: string(),
      //.typeError("El segundo apellido de ser una cadena de caracteres"),
  business_name: string()
      .typeError("La Razón Social debe ser una cadena de carácteres")
      /*.when('document_type_id', {
        is: (value) => value == 2 || value == 4,
        then: (schema) => schema.required('La Razón Social es requerida')
      })*/,
  }),
  contactData: object().shape({
  address: string().typeError(
      "La dirección debe ser una cadena de caracteres")
      .required("La dirección es requerida"),
  email_delivery: string()
      .typeError("E-mail inválido")
      .email("E-mail inválido")
      .required("El E-mail es requerido"),
  }),
})

/*--------------------------------------------
ESQUEMAS DE VALIDACION ENVIO KEYEDIT AL E-MAIL
---------------------------------------------*/

export const validationDocumentSchema = object().shape({
    peopleData: object().shape({
      document: string()
        .typeError("El documento debe ser una cadena de carácteres")
        .required("El documento es requerido"),
    })
});

/*--------------------------------------------------
ESQUEMAS DE VALIDACION DOCUMENTO Y KEY DE LA PERSONA
---------------------------------------------------*/

export const validationDocumentAndKeySchema = object().shape({
    peopleData: object().shape({
      document: string()
        .typeError("El documento debe ser una cadena de carácteres")
        .required("El documento es requerido"),

      edition_key: string()
        .typeError("La llave de edición debe ser una cadena de carácteres")
        .required("La llave de edición es requerida"),
    }),
  });


/*--------------------------------------------------
ESQUEMAS DE VALIDACION DE KEY DE LA PERSONA
---------------------------------------------------*/

export  const validationKeySchema = object().shape({
    peopleData: object().shape({
      edition_key: string()
        .typeError("La llave de edición debe ser una cadena de carácteres")
        .required("La llave de edición es requerida"),
    }),

  });

/*--------------------------------------------------
ESQUEMAS DE VALIDACION DE VENTAS PARA FACTURAR

---------------------------------------------------*/
export  const validationSaleSchema = object().shape({
    
    billingData: object().shape({
      pcc: string()
      .typeError("El PCC debe ser una cadena de carácteres")
      .required("El PCC es requerido"),

      receipt: string()
      .typeError("El identificador de venta debe ser una cadena de carácteres")
      .required("El identificador de venta es requerido"),

      sale_code: string()
      .typeError("El código debe ser una cadena de carácteres")
      .required("El código es requerido"),
    })
  });