/**
 * Calcular el dígito de verificación
 * @param {string} document Documento
 * @returns {number} Dígito de verificación
 */
export default function getDV(document) {
    let check = 0;
    const paArray = [
        3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71
    ];

    for (let i = 0; i < document.length; i++) {
        check += parseInt(document[document.length - 1 - i]) * paArray[i];
    }

    const leftover = check % 11;
    return (leftover > 1) ? 11 - leftover : leftover;
}